import * as React from "react";
import Container from "../components/Container";
import Richtext from "../components/Richtext";

// markup
const NotFoundPage = () => {
  return (
    <main id="error404">
      <Container size="xs">
        <Richtext align="center">
          <span>404</span>
          <h1>Nothing Found</h1>
          <p>Sorry the page doesn't exist.</p>
        </Richtext>
      </Container>
    </main>
  );
};

export default NotFoundPage;
